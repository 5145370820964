import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import CarList from "./components/CarList";
import BlogPage from "./components/BlogPage";
import ScrollToTop from "./components/ScrollToTop";
import NotFoundPage from "./components/NotFoundPage";
import AboutUs from "./components/AboutUs";
import AllBlogs from "./components/AllBlogs";
import OnsAanbod from "./components/OnsAanbod";
import PrivateLeasePage from "./components/PrivateLeasePage";
import ZakelijkLeasePage from "./components/ZakelijkLeasePage";
import OccasionLeasePage from "./components/OccasionLeasePage";
import AutomerkenPage from "./components/Automerken";

const Homepage = lazy(() => import("./components/Landingpage"));
const DemoCards = lazy(() => import("./components/DemoCards"));
const ExtraInfo = lazy(() => import("./components/ExtraInfo"));
const BlogHomepage = lazy(() => import("./components/BlogPreview"));
const CarCards = lazy(() => import("./components/CarBrandCards"));
const AllLinks = lazy(() => import("./components/AllLinks"));

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<EveryElement />} />
          <Route
            path="/Elektrische-Lease-vergelijken"
            element={<MainCarPage />}
          />
          <Route path="/:category/:blogUrl" element={<BlogPageWrapper />} />
          <Route path="/Over-ons" element={<AboutUsPage />} />
          <Route path="/Nieuws" element={<AlltheBlogs />} />
          <Route path="/Aanbod" element={<AanbodPage />} />
          <Route path="/Private-Lease" element={<PrivatePage />} />
          <Route path="/Zakelijk-Lease" element={<ZakelijkePage />} />
          <Route path="/Occasion-Lease" element={<OccasionLeasePage />} />
          <Route path="/Auto-merken" element={<AutomerkenMainPage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
}

function AboutUsPage() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="text-2xl text-primary-color font-bold">
            Loading...
          </div>
        }
      >
        <AboutUs />
        <Footer />
      </Suspense>
    </div>
  );
}

function MainCarPage() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="text-2xl text-primary-color font-bold">
            Loading...
          </div>
        }
      >
        <CarList />
        <Footer />
      </Suspense>
    </div>
  );
}

function ZakelijkePage() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="text-2xl text-primary-color font-bold">
            Loading...
          </div>
        }
      >
        <ZakelijkLeasePage />
        <Footer />
      </Suspense>
    </div>
  );
}

function PrivatePage() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="text-2xl text-primary-color font-bold">
            Loading...
          </div>
        }
      >
        <PrivateLeasePage />
        <Footer />
      </Suspense>
    </div>
  );
}

function EveryElement() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="text-2xl text-primary-color font-bold">
            Loading...
          </div>
        }
      >
        <Homepage />
        <DemoCards />
        <ExtraInfo />
        <BlogHomepage />
        <CarCards />
        <AllLinks />
        <Footer />
      </Suspense>
    </div>
  );
}

function AlltheBlogs() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="text-2xl text-primary-color font-bold">
            Loading...
          </div>
        }
      >
        <AllBlogs />
        <Footer />
      </Suspense>
    </div>
  );
}

function AanbodPage() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="text-2xl text-primary-color font-bold">
            Loading...
          </div>
        }
      >
        <OnsAanbod />
        <Footer />
      </Suspense>
    </div>
  );
}

function AutomerkenMainPage() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="text-2xl text-primary-color font-bold">
            Loading...
          </div>
        }
      >
        <AutomerkenPage />
        <Footer />
      </Suspense>
    </div>
  );
}

function BlogPageWrapper() {
  const { category, blogUrl } = useParams();
  return (
    <Suspense
      fallback={
        <div className="text-2xl text-primary-color font-bold">Loading...</div>
      }
    >
      <BlogPage category={category} blogUrl={blogUrl} />
    </Suspense>
  );
}

export default App;

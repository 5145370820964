import React, { useState } from "react";
import { Link } from "react-router-dom";
import elnllogo from "../assets/elnlmainlogo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrFormClose } from "react-icons/gr";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    const newMenuOpen = !menuOpen;
    setMenuOpen(newMenuOpen);

    if (newMenuOpen) {
      disablePageScroll();
    } else {
      enablePageScroll();
    }
  };

  const closeMenu = () => {
    enablePageScroll();
    setMenuOpen(false);
  };

  const linkClass = ({ isActive }) =>
    isActive
      ? "text-primary-color font-bold hover:text-primary-color duration-300"
      : "text-[#464646] font-bold hover:text-primary-color duration-300";

  return (
    <nav className="bg-[#f2f2f2]  py-4 z-20 font-sans w-full">
      <div className="flex items-center justify-around mx-auto">
        <div className="flex items-center">
          <Link
            to="/"
            className="text-[#464646] text-2xl font-bold flex items-center"
          >
            <img src={elnllogo} alt="Logo" className="w-20 h-10 mr-2 sm:w-30" />
            <div>
              <span className="text-primary-color">Elektrische-</span>
              <span className="text-[#464646]">Lease.nl</span>
            </div>
          </Link>
        </div>

        <div className="items-center hidden lg:flex space-x-8">
          <NavLink to="/" className={linkClass} onClick={closeMenu}>
            Home
          </NavLink>
          <NavLink
            to="/Private-Lease"
            className={linkClass}
            onClick={closeMenu}
          >
            Private Lease
          </NavLink>
          <NavLink
            to="/Zakelijk-Lease"
            className={linkClass}
            onClick={closeMenu}
          >
            Zakelijke Lease
          </NavLink>
          <NavLink to="/Aanbod" className={linkClass} onClick={closeMenu}>
            Aanbod
          </NavLink>
          <NavLink to="/Over-ons" className={linkClass} onClick={closeMenu}>
            Over ons
          </NavLink>
          <NavLink to="/Nieuws" className={linkClass} onClick={closeMenu}>
            Nieuws
          </NavLink>
          <NavLink to="/Auto-merken" className={linkClass} onClick={closeMenu}>
            Automerken
          </NavLink>
        </div>

        <div className="lg:hidden">
          <button
            className="text-[#464646] focus:outline-none"
            onClick={toggleMenu}
          >
            {menuOpen ? (
              <GrFormClose size={40} />
            ) : (
              <GiHamburgerMenu size={25} className="mt-2 mr-2" />
            )}
          </button>
        </div>
      </div>

      {menuOpen && (
        <div className="fixed inset-0 z-50 transition-transform duration-300 ease-in transform bg-gray-100 lg:hidden">
          <div className=" z-40 h-[60vh] pt-4 pl-4 space-y-8">
            <div className="flex justify-end">
              <button
                className="text-[#464646] focus:outline-none"
                onClick={toggleMenu}
              >
                <GrFormClose size={40} />
              </button>
            </div>
            <NavLink
              to="/"
              className="text-[#464646] text-4xl font-bold hover:text-primary-color block"
              onClick={closeMenu}
            >
              Home
            </NavLink>
            <NavLink
              to="/Private-Lease"
              className="text-[#464646] text-4xl font-bold hover:text-primary-color block"
              onClick={closeMenu}
            >
              Private Lease
            </NavLink>
            <NavLink
              to="/Zakelijk-Lease"
              className="text-[#464646] text-4xl font-bold hover:text-primary-color block"
              onClick={closeMenu}
            >
              Zakelijke Lease
            </NavLink>
            <NavLink
              to="/Aanbod"
              className="text-[#464646] text-4xl font-bold hover:text-primary-color block"
              onClick={closeMenu}
            >
              Aanbod
            </NavLink>
            <NavLink
              to="/Over-ons"
              className="text-[#464646] text-4xl font-bold hover:text-primary-color block"
              onClick={closeMenu}
            >
              Over ons
            </NavLink>
            <NavLink
              to="/Nieuws"
              className="text-[#464646] text-4xl font-bold hover:text-primary-color block"
              onClick={closeMenu}
            >
              Nieuws
            </NavLink>
            <NavLink
              to="/"
              className="text-[#464646] text-4xl font-bold hover:text-primary-color block"
              onClick={closeMenu}
            >
              Automerken
            </NavLink>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;

import React from "react";

const AboutUs = () => {
  return (
    <div className="sm:w-[50vw] w-[80vw] mx-auto text-[#464646] mt-[10vh] sm:mt-[8vh] prose">
      <h1 className="mb-8 text-2xl font-bold sm:text-5xl">
        Wij zijn <span className="text-primary-color">Elektrische-</span>
        Lease.nl, de specialist in elektrische leaseauto's
      </h1>

      <h2 className="mt-8 text-2xl font-bold">Over Ons</h2>
      <p className="text-[#464646] mb-8 text-lg">
        Welkom bij Elektrische-Lease.nl! Wij zijn Kevin en Rei, twee
        enthousiaste individuen die gepassioneerd zijn over elektrische auto's
        en duurzame mobiliteit. Onze vriendschap begon vijf jaar geleden, en
        sindsdien hebben we besloten om onze krachten te bundelen en Nederland
        te helpen bij de overgang naar een groenere toekomst.
      </p>

      <h2 className="mt-8 text-2xl font-bold">Onze Missie</h2>
      <p className="text-[#464646] mb-4  text-lg">
        Onze missie is eenvoudig maar krachtig: we willen Nederlandse
        bestuurders helpen om moeiteloos toegang te krijgen tot betaalbare
        elektrische leaseauto's. We begrijpen dat de wereld van elektrisch
        leasen complex kan zijn, met talloze aanbieders en prijzen. Daarom
        hebben we Elektrische-Lease.nl opgericht - een platform dat alles in één
        overzicht biedt. Door transparantie en duidelijkheid te brengen, streven
        we ernaar om ervoor te zorgen dat niemand te veel betaalt voor zijn
        elektrische leaseauto.
      </p>
      <h2 className="mt-8 text-2xl font-bold">Onze Focus op Duurzaamheid</h2>
      <p className="text-[#464646] mb-4 text-lg">
        Naast het helpen van individuen om geld te besparen, streven we ernaar
        Nederland groener en ecologischer te maken. We geloven dat elektrische
        auto's een belangrijke rol spelen in de toekomst van mobiliteit. Met de
        groeiende trend van plekken die alleen toegankelijk zijn voor
        elektrische auto's, dragen we bij aan de ecologische transitie van ons
        land.
      </p>
      <h2 className="mt-8 text-2xl font-bold">Ontmoet het Team</h2>
      <p className="text-[#464646] mb-4 text-lg">
        Kevin - De Marketeer: Kevin is onze marketingexpert die zich bezighoudt
        met het verspreiden van het woord over elektrische mobiliteit. Geboren
        in Spijkenisse, brengt hij zijn passie voor marketing in om onze
        boodschap te verspreiden en meer mensen bewust te maken van de voordelen
        van elektrisch leasen.
      </p>
      <p className="text-[#464646] mb-4 text-lg">
        Rei - De Ontwikkelaar: Rei is onze getalenteerde ontwikkelaar,
        verantwoordelijk voor het ontwerpen, bouwen en onderhouden van onze
        gebruiksvriendelijke website. Zijn technische vaardigheden zorgen ervoor
        dat ons platform soepel functioneert, zodat jullie moeiteloos de
        perfecte elektrische leaseauto kunnen vinden.
      </p>

      <h2 className="mt-8 text-2xl font-bold">Contactgegevens</h2>
      <p className="text-[#464646] mb-4 text-lg">
        Wil je meer informatie over elektrisch leasen of heb je specifieke
        vragen? Neem gerust contact met ons op. We staan klaar om je te helpen!
        E-mail: info@elektrische-lease.nl Telefoon: +31 0634608126 Adres:
        Gaddijk 21, 3205LA Spijkenisse, Nederland Aarzel niet om ons te bellen
        of een e-mail te sturen als je hulp nodig hebt bij het vinden van de
        perfecte elektrische leaseauto. We kijken ernaar uit om van je te horen!
        Met groene groeten, Kevin & Rei Het Team van Elektrische-Lease.nl
      </p>
    </div>
  );
};

export default AboutUs;

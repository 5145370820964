import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../index.css";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import data from "../data/final_total_elektrische_data.json";
import LeasemLogo from "../assets/LeasemLogo.jpg";
import JustLeaseLogo from "../assets/JustLeaseLogo.jpg";
import AthlonLogo from "../assets/AthlonLogo.jpg";
import CarsubLogo from "../assets/CarSubLogo.png";
import AutoNlLogo from "../assets/autoNlLogo.png";
import ToyotaLogo from "../assets/toyotalogo.png";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "../slider.css";

function CarList() {
  const location = useLocation();
  const [cars, setCars] = useState(data);
  const [filteredCars, setFilteredCars] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage] = useState(15);
  const queryParams = new URLSearchParams(location.search);
  const selectedPage = parseInt(queryParams.get("page")) || 1;
  const selectedLeaseType = queryParams.get("leaseType") || "Alle";
  const selectedPartner = queryParams.get("partner") || "All";
  const selectedSortOrder = queryParams.get("sortOrder") || "laag-hoog";
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [popupCar, setPopupCar] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 1000]);

  useEffect(() => {
    if (selectedPage !== page) {
      setPage(selectedPage);
    }
  }, [selectedPage, page]);

  useEffect(() => {
    // If the component is still loading, return early
    if (isLoading) {
      return;
    }

    let filtered = cars;

    if (selectedLeaseType !== "Alle") {
      filtered = filtered.filter(
        (car) => car.LeaseType.toLowerCase() === selectedLeaseType.toLowerCase()
      );
    }

    if (selectedPartner !== "All") {
      filtered = filtered.filter(
        (car) => car.Partner.toLowerCase() === selectedPartner.toLowerCase()
      );
    }

    if (selectedSortOrder === "laag-hoog") {
      filtered = filtered.sort(
        (a, b) => parseFloat(a.Price) - parseFloat(b.Price)
      );
    } else {
      filtered = filtered.sort(
        (a, b) => parseFloat(b.Price) - parseFloat(a.Price)
      );
    }

    filtered = filtered.filter(
      (car) =>
        parseFloat(car.Price) >= priceRange[0] &&
        parseFloat(car.Price) <= priceRange[1]
    );

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedCars = filtered.slice(startIndex, endIndex);
    setFilteredCars(slicedCars);
  }, [
    cars,
    page,
    perPage,
    selectedLeaseType,
    selectedPartner,
    selectedSortOrder,
    isLoading,
    priceRange,
  ]);

  const partnerLogos = {
    Leasem: LeasemLogo,
    JustLease: JustLeaseLogo,
    // Athlon: AthlonLogo,
    "Auto.nl": AutoNlLogo,
    Toyota: ToyotaLogo,
  };

  const handlePriceChange = (newPriceRange) => {
    setPriceRange(newPriceRange);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams();
    queryParams.set("page", page.toString());
    queryParams.set("leaseType", selectedLeaseType);
    queryParams.set("partner", selectedPartner);
    queryParams.set("sortOrder", selectedSortOrder);

    history(`?${queryParams.toString()}`);
  }, [page, selectedLeaseType, selectedPartner, selectedSortOrder, history]);

  const handlePreviousPage = () => {
    if (page > 1) {
      const prevPage = page - 1;
      const queryParams = new URLSearchParams(location.search);
      queryParams.set("page", prevPage.toString());
      history(`?${queryParams.toString()}`);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    }
  };

  const handleNextPage = (e) => {
    e.preventDefault();
    const nextPage = page + 1;
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", nextPage.toString());
    history(`?${queryParams.toString()}`);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  };

  const handleLeaseTypeChange = (selectedValue) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("leaseType", selectedValue);
    queryParams.delete("page");
    history({ search: queryParams.toString() });
  };

  const handlePartnerChange = (selectedValue) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("partner", selectedValue);
    queryParams.delete("page");
    history({ search: queryParams.toString() });
  };

  const handleSortChange = () => {
    const newSortOrder =
      selectedSortOrder === "laag-hoog" ? "hoog-laag" : "laag-hoog";
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("sortOrder", newSortOrder);
    queryParams.delete("page");
    history({ search: queryParams.toString() });
  };
  return (
    <div
      className={`container mx-auto p-4 flex flex-col sm:flex-row min-h-screen `}
    >
      {page === 1 ? (
        <div className="w-full sm:w-1/4 p-4 sm:mt-[14vh]">
          <h1 className="sm:hidden text-3xl sm:text-5xl font-bold mb-8 mt-4 sm:mt-[8vh] text-[#464646]">
            Vind jouw ideale{" "}
            <span className="text-primary-color">Elektrische Leaseauto</span>{" "}
            vandaag!
          </h1>
          <h1 className="text-xl font-bold mb-2 text-[#464646]">
            Filter Opties:
          </h1>
          <button
            onClick={handleSortChange}
            className="text-white mb-2 font-bold bg-primary-color border-2  py-2 px-4 rounded-xl hover:bg-[#ec8f4c] transition-colors duration-300"
          >
            Sorteer:{" "}
            {selectedSortOrder === "laag-hoog" ? "Laag-Hoog" : "Hoog-Laag"}
          </button>
          <select
            value={selectedLeaseType || "Alle"}
            onChange={(e) => handleLeaseTypeChange(e.target.value)}
            className="w-full p-2 mb-2 font-bold border rounded-md cursor-pointer"
          >
            <option value="Alle">Alle Lease Types</option>
            {/* <option value="Zakelijk">Zakelijk</option> */}
            <option value="Private Lease">Private Lease</option>
            <option value="ShortLease">Short Lease</option>
          </select>
          <select
            value={selectedPartner || "All"}
            onChange={(e) => handlePartnerChange(e.target.value)}
            className="w-full p-2 mb-2 font-bold border rounded-md cursor-pointer"
          >
            <option value="All">Alle Partners</option>
            <option value="Leasem">Leasem</option>
            <option value="Auto.nl">Auto.nl</option>
            <option value="Toyota">Toyota</option>
            {/* <option value="Carsub">Carsub</option> */}
            {/* <option value="Athlon">Athlon</option> */}
            <option value="JustLease">JustLease</option>
          </select>

          <div className="px-4 py-2 mt-2 mb-3 bg-white border-2 rounded-xl ">
            <h3 className="text-[#464646] font-bold mb-2">Prijsbereik</h3>
            <Slider
              range
              min={100}
              max={1500}
              defaultValue={priceRange}
              value={priceRange}
              onChange={handlePriceChange}
            />
            <div className="flex justify-between">
              <span>€{priceRange[0]}</span>
              <span>€{priceRange[1]}</span>
            </div>
          </div>
        </div>
      ) : null}
      <div className={`w-full sm:w-3/4 ${page !== 1 ? "mx-auto" : ""}`}>
        <h1 className="hidden sm:flex sm:text-4xl font-bold mb-8 mt-4 sm:mt-[8vh] text-[#464646]">
          Vind jouw ideale{" "}
          <span className="px-3 text-primary-color">Elektrische Leaseauto</span>{" "}
          vandaag!
        </h1>
        <ul className="grid grid-cols-1 gap-4 text-[#464646]">
          {filteredCars.length === 0 && (
            <p className="text-5xl font-bold text-primary-color sm:pl-[12vw] sm:mt-[15vh] mb-[20vh]">
              Sorry,{" "}
              <span className="text-[#464646]">er zijn geen auto's meer.</span>
            </p>
          )}
          {filteredCars.map((car) => (
            <li
              key={car.ImageUrls}
              className="border-2 pr-4 rounded-lg bg-white hover:shadow-lg h-auto sm:pb-0  sm:h-[30vh] flex flex-col md:flex-row items-center "
            >
              {/* Car Image Section */}
              <div className="md:w-1/3 sm:pr-2">
                <a onClick={() => window.open(car.CarUrl, "_blank")}>
                  <img
                    src={car.ImageUrls}
                    alt={car.Name}
                    className="w-full md:w-[40vh] sm:pb-0 pb-4 h-auto mx-auto md:mx-0 cursor-pointer"
                    loading="lazy"
                  />
                </a>
              </div>

              {/* Car Details Section */}
              <div className="md:w-2/3 w-[70vw]">
                {/* Lease Type */}
                <p className="flex flex-col items-start text-sm font-bold text-primary-color sm:flex-row sm:justify-between sm:items-end">
                  {car.LeaseType}
                </p>

                {/* Car Name */}
                <div className="flex flex-col items-start justify-between md:flex-row">
                  <h2 className="overflow-hidden text-3xl font-bold text-left sm:text-4xl">
                    {car.Name}
                  </h2>
                  <h3 className="text-2xl sm:text-4xl font-bold text-[#464646] text-center sm:text-left">
                    €{car.Price},-
                    <span className="mt-2 text-lg sm:text-2xl sm:mt-0">
                      p/m
                    </span>
                  </h3>
                </div>

                {/* Partner Logo */}
                <p>
                  <img
                    src={partnerLogos[car.Partner]}
                    alt={car.Name}
                    className="w-auto h-6"
                    loading="lazy"
                  />
                </p>

                {/* Additional Details */}
                <div className="w-full md:w-[60%] h-[5h] mt-2">
                  <div className="flex flex-col justify-between ml-auto">
                    <div className="flex items-center text-sm sm:text-md">
                      <AiOutlineCheckCircle
                        className="text-green-600"
                        size={20}
                      />{" "}
                      All-risk
                    </div>
                    <div className="flex items-center text-sm sm:text-md">
                      <AiOutlineCheckCircle
                        className="text-green-600"
                        size={20}
                      />{" "}
                      Wegenbelasting
                    </div>
                    <div className="flex items-center text-sm sm:text-md">
                      <AiOutlineCheckCircle
                        className="text-green-600 "
                        size={20}
                      />{" "}
                      Onderhoud
                    </div>
                    <a
                      href={car.CarUrl}
                      target="_blank"
                      className=" text-primary-color hover:underline"
                    >
                      meer info:
                    </a>
                  </div>
                </div>

                {/* Action Button */}
                <div className="flex justify-end mt-5 sm:mt-0">
                  <p className="w-full md:w-[30%] h-[6vh] sm:h-[5vh] pb-4 flex flex-col justify-end">
                    <button
                      onClick={() => window.open(car.CarUrl, "_blank")}
                      className="flex items-center text-secondary-color justify-center font-bold bg-primary-color sm:py-2 w-full h-[6vh] px-4 rounded-xl hover:bg-[#ec8f4c] transition-colors duration-300"
                      rel="nofollow"
                    >
                      <span className="">Bekijk beste prijs</span>
                      <AiOutlineArrowRight size={15} />
                    </button>
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="flex justify-center gap-8 mt-8 mb-10">
          <button
            onClick={handlePreviousPage}
            className=" bg-primary-color text-secondary-color px-4 py-2 rounded-lg w-[30vw] sm:w-[8vw] font-bold sm:h-[5vh] h-[7vh] cursor-pointer hover:bg-[#ec8f4c] transition-colors duration-300"
            disabled={page === 1}
          >
            Vorige
          </button>
          <button
            onClick={handleNextPage}
            className=" bg-primary-color text-secondary-color px-4 py-2 rounded-lg w-[30vw] sm:w-[8vw] font-bold sm:h-[5vh] h-[7vh] cursor-pointer hover:bg-[#ec8f4c] transition-colors duration-300"
            style={{
              visibility: filteredCars.length < perPage ? "hidden" : "visible",
            }}
          >
            Volgende
          </button>
        </div>
      </div>
    </div>
  );
}

export default CarList;

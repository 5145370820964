import React from "react";

const AutomerkenPage = () => {
  return (
    <div className="sm:w-[50vw] w-[80vw] mx-auto text-[#464646] mt-[10vh] sm:mt-[8vh]">
      <h1 className="mb-8 text-3xl font-bold sm:text-5xl">
        Een Diepgaande Blik op{" "}
        <span className="text-primary-color">Elektrische Auto</span>{" "}
        Statistieken: Overzicht van Populaire Merken en Modellen
      </h1>

      <h2 className="mt-10 text-3xl font-bold">
        De Opkomst van Elektrische Auto's: Een Diepgaande Analyse van
        Statistieken
      </h2>
      <h2 className="mt-8 text-xl font-bold">
        Verken de wereld van elektrische mobiliteit en ontdek welke merken en
        modellen de boventoon voeren in 2023.
      </h2>
      <p className="text-[#464646] my-8 text-lg">
        <a
          href="https://elektrische-lease.nl/Private-Lease"
          className="text-primary-color hover:underline"
        >
          Lees meer over elektrische auto leasen voor particulieren
        </a>
      </p>

      <h2 className="mt-8 text-2xl font-bold">
        Populairste Elektrische Auto's in het Eerste Halfjaar van 2023
      </h2>
      <p className="text-[#464646] mb-4  text-lg">
        Hier zijn de top 5 elektrische auto's die de markt domineren in 2023:
      </p>
      <li className="text-[#464646] mb-4  text-lg">
        1. Tesla Model Y - 1778 registraties
      </li>
      <li className="text-[#464646] mb-4  text-lg">
        2. Peugeot e-208 - 1166 registraties
      </li>
      <li className="text-[#464646] mb-4  text-lg">
        3. Tesla Model 3 - 860 registraties
      </li>
      <li className="text-[#464646] mb-4  text-lg">
        {" "}
        4. Skoda Enyaq iV - 588 registraties
      </li>
      <li className="text-[#464646] mb-4  text-lg">
        {" "}
        5. MG ZS EV - 541 registraties
      </li>

      <p className="text-[#464646] mb-8  text-lg">
        <a
          className="text-primary-color hover:underline"
          href="https://elektrische-lease.nl/Zakelijk-Leasen/Tesla-model-3"
        >
          Bekijk de zakelijke leaseopties voor de Tesla Model 3
        </a>
      </p>

      <h3 className="mt-8 text-3xl font-bold">
        Populairste Elektrische Auto's in Nederland - Juni 2023
      </h3>
      <p className="text-[#464646] mb-4 text-lg">Statistieken per Automerk</p>
      <h1></h1>
      <p className="text-[#464646] mb-8 text-lg">
        <a
          className="text-primary-color hover:underline"
          href="https://elektrische-lease.nl/Private-Lease/elektrische_leaseauto_2024_vergelijken"
        >
          Vergelijk de goedkoopste private leaseopties voor elektrische auto's
          in 2024
        </a>
      </p>

      <h2 className="mt-8 mb-4 text-2xl font-bold">
        Audi - Veranderingen in Registraties van E-Tron Modellen
      </h2>
      <p className="text-[#464646] mb-4 text-lg">
        Audi's e-tron modellen hebben gezien dat de registraties afnemen,
        behalve voor de nu Q8 e-tron genaamde SUV's. In het eerste halfjaar zijn
        550 stuks verkocht.
      </p>

      <h3 className="mt-8 mb-4 text-3xl font-bold">E-Tron GT en Q4 E-Tron</h3>
      <p className="text-[#464646] mb-4 text-lg">
        Van de E-Tron GT werden 54 exemplaren geregistreerd, 34 minder dan in
        het eerste halfjaar van 2022. De Q4 e-Tron had 1464 registraties, iets
        minder dan vorig jaar{" "}
        <span className="text-primary-color">1527 stuks</span>.
      </p>
      <p className="text-[#464646] mb-4 text-lg">
        BMW - Positieve Trend in Elektrische Modellen
      </p>
      <p className="text-[#464646] mb-4 text-lg">
        BMW's elektrische modellen presteren over het algemeen beter dan in het
        eerste halfjaar van 2022. De i4 zag 1619 registraties, driemaal zoveel
        als in 2022.
      </p>

      <h2 className="mt-8 mb-4 text-2xl font-bold">Andere Modellen</h2>
      <p className="text-[#464646] mb-4 text-lg">
        De i7 had 96 registraties, de nieuwe iX1 1360 exemplaren en de iX3 ging
        van 884 registraties in 2022 naar 907 registraties in 2023.
      </p>

      <h4 className="mt-8 mb-4 text-3xl font-bold">
        Mercedes-Benz - Winst voor EQE, Daling voor EQS en EQV"
      </h4>
      <p className="text-[#464646] mb-8 text-lg">
        De EQ-modellen van Mercedes-Benz doen het beter dan vorig jaar, behalve
        de EQS en EQV. De EQE zag de grootste winst met 395 registraties, ruim
        tweemaal zoveel als in 2022.
      </p>
      <h2 className="my-10 text-4xl font-bold text-primary-color">
        Opkomst van Chinese Merken
      </h2>
      <h3 className="mt-8 mb-4 text-3xl font-bold">
        BYD, Hongqi, en XPeng in Nederland
      </h3>
      <p className="text-[#464646] mb-4 text-lg">
        Chinese merken zoals BYD, Hongqi, en XPeng winnen terrein. BYD's Atto 3
        had 557 registraties, terwijl Han en Tan slechts 14 stuks hadden.
        Hongqi's E-HS9 had 31 registraties. XPeng's P7 had geen registraties,
        maar één P5 werd geregistreerd.
      </p>
      <h2 className="mt-8 mb-4 text-3xl font-bold">
        Prestaties van MG, Nio en Andere Merken
      </h2>
      <p className="text-[#464646] mb-4 text-lg">
        MG's betaalbare modellen deden het goed, met de MG 4 Electric die 1122
        verkopen had. Nio's ET5, ET7, en EL7 hadden respectievelijk 36, 24, en
        31 registraties. XPeng had geen P7-registraties, maar één P5 kreeg een
        Nederlands kenteken.
      </p>
      <h3 className="mt-8 mb-4 text-3xl font-bold">
        Honda e en Jaguar I-Pace - Beperkte Zichtbaarheid
      </h3>
      <p className="text-[#464646] mb-4 text-lg">
        De Honda e en Jaguar I-Pace waren nauwelijks zichtbaar, met
        respectievelijk 4 en 5 registraties.
      </p>
      <h2 className="mt-8 mb-4 text-3xl font-bold text-primary-color">
        Koreaanse Kracht en Tegenvallers voor Tesla
      </h2>
      <h3 className="mt-8 mb-4 text-3xl font-bold">Hyundai en Kia's Succes</h3>
      <p className="text-[#464646] mb-4 text-lg">
        Hyundai deed goede zaken met de Ioniq 5{" "}
        <span className="font-bold">1379 registraties</span> en de Kia EV6{" "}
        <span className="font-bold">1415 registraties</span>. Tesla's Model S en
        Model X hadden elk 160 en 159 registraties.
      </p>
      <h3 className="mt-8 mb-4 text-3xl font-bold">Andere Modellen</h3>
      <p className="text-[#464646] mb-4 text-lg">
        De Volkswagen ID5 en ID Buzz hadden respectievelijk 385 en 210
        registraties. De Volvo C40 had 1093 registraties.
      </p>
      <h2 className="mt-8 text-3xl font-bold text-primary-color">
        Elektrische Exoten en Bekende Namen
      </h2>
      <h3 className="mt-8 mb-4 text-3xl font-bold">
        Nieuwkomers en Minder Zichtbare Modellen
      </h3>
      <p className="text-[#464646] mb-4 text-lg">
        De Volkswagen ID5 en ID Buzz hadden respectievelijk 385 en 210
        registraties. De Volvo C40 had 1093 registraties.
      </p>
      <p className="text-[#464646] mb-4 text-lg">
        <a
          className="text-primary-color hover:underline"
          href="https://elektrische-lease.nl/Private-Lease/Voordelige-Leaseopties-2024"
        >
          Ontdek voordelige leaseopties voor elektrische auto's in 2024
        </a>
      </p>
      <p className="text-[#464646] mb-4 text-lg">
        <a
          className="text-primary-color hover:underline"
          href="https://elektrische-lease.nl/Private-Lease/Top-10"
        >
          Bekijk de top 10 van elektrische auto's
        </a>
      </p>
      <p className="text-[#464646] mb-4 text-lg">
        <a
          className="text-primary-color hover:underline"
          href="https://elektrische-lease.nl/Lease-uitleg/Leaseopties-uitleg"
        >
          Lees meer over leaseopties en uitleg over elektrische auto's
        </a>
      </p>
    </div>
  );
};

export default AutomerkenPage;

import React, { useState } from "react";
import blogsData from "../data/blog.json";
import { Link } from "react-router-dom";

const AllBlogs = () => {
  const [blogsToShow, setBlogsToShow] = useState(8);
  const reversedBlogs = [...blogsData].reverse();
  const visibleBlogs = reversedBlogs.slice(0, blogsToShow);

  const handleShowMore = () => {
    setBlogsToShow((prev) => prev + 8);
  };

  return (
    <div className="md:w-4/5 mx-auto mt-[4vh] flex flex-col items-center">
      <h2 className="text-3xl md:text-5xl text-center font-bold sm:mt-[10vh] justify-center text-[#464646] max-w-xs md:max-w-full">
        Lees onze <span className="text-primary-color">artikelen</span>
      </h2>

      <div className="flex flex-col w-full mt-8">
        {visibleBlogs.map((blog) => (
          <Link
            key={blog.blog_url}
            to={`/${blog.category}/${blog.blog_url}`}
            className="flex flex-col md:flex-row mb-4 bg-white shadow-lg space-y-4 rounded-xl  sm:p-0 py-2 sm:w-[60vw] mx-2 sm:mx-auto"
          >
            <div
              className="bg-cover bg-center w-full h-[25vh] md:w-1/3 md:h-auto  md:rounded-l-xl"
              style={{
                backgroundImage: `url(${blog.image})`,
              }}
            ></div>

            <div className="flex-1 p-4 md:pl-6">
              <p className="text-[#464646] font-bold">
                {blog.category.replace(/-/g, " ")}
              </p>

              <p className="mt-2 text-xl font-bold text-primary-color md:mt-0">
                {blog.title.split(" ").slice(0, 8).join(" ")}
              </p>
              <p className="mt-2 text-lg">
                {blog.content
                  .find(
                    (item) =>
                      item.type === "h1-1" ||
                      item.type === "p-1" ||
                      item.type === "p"
                  )
                  ?.text.split(" ")
                  .slice(0, 15)
                  .join(" ")}
                ...
              </p>

              <div className="w-full mt-4 border-t border-gray-300 md:w-auto">
                <div className="flex flex-col justify-between p-2 md:flex-row md:mt-2">
                  <p className="mb-2 text-sm text-primary-color md:mb-0">
                    {blog.created} - {blog.reading_time}
                  </p>
                  <button className="text-white font-bold bg-primary-color px-4 py-2 rounded-xl hover:bg-[#ec8f4c] transition-colors duration-300">
                    Lees verder
                  </button>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {visibleBlogs.length < reversedBlogs.length && (
        <button
          onClick={handleShowMore}
          className="text-white font-bold bg-primary-color border-2 border-primary-color px-4 py-2 rounded-xl mt-4 mb-8 hover:bg-[#ec8f4c] transition-colors duration-300"
        >
          Toon meer
        </button>
      )}
    </div>
  );
};

export default AllBlogs;

import React from "react";

const OnsAanbod = () => {
  return (
    <div className="sm:w-[50vw] w-[80vw] mx-auto text-[#464646] mt-[10vh] sm:mt-[8vh] prose">
      <h1 className="mb-8 text-2xl font-bold sm:text-5xl text-primary-color">
        <span className="text-[#464646]">Ons</span> Aanbod
      </h1>

      <h2 className="mt-8 mb-4 text-2xl font-bold">Ons Aanbod</h2>
      <p className="text-[#464646] mb-8 text-lg">
        Welkom bij Elektrische-Lease, jouw ultieme bron voor elektrisch rijden.
        Wij zijn gepassioneerd over het aanbieden van hoogwaardige
        lease-oplossingen die passen bij jouw behoeften en budget. Bij ons vind
        je niet alleen de meest uitgebreide selectie van elektrische auto
        modellen, maar we garanderen ook altijd de laagste prijs. Ontdek ons
        diverse aanbod en vind de perfecte elektrische auto die bij jou past.
      </p>

      <h2 className="mt-8 mb-4 text-2xl font-bold">Wat Wij Bieden</h2>
      <p className="text-[#464646] mb-4  text-lg">
        Private Lease Geniet van de vrijheid van een nieuwe elektrische auto
        zonder je zorgen te maken over onverwachte kosten. Met onze private
        leaseopties hoef je alleen maar te rijden, terwijl wij voor de rest
        zorgen. Ontdek onze transparante prijzen en kies uit ons assortiment van
        betrouwbare elektrische voertuigen.
      </p>
      <h2 className="mt-8 mb-4 text-2xl font-bold">Zakelijk Leasen</h2>
      <p className="text-[#464646] mb-4 text-lg">
        Bij zakelijk leasen bieden we flexibele oplossingen voor bedrijven van
        elke omvang. Profiteer van voordelige tarieven, uitstekend onderhoud en
        een breed scala aan beschikbare elektrische modellen. Wij vergelijken
        alle aanbieders en garanderen de laagste prijs, zodat jij zorgeloos
        elektrisch kunt rijden.
      </p>
      <h2 className="mt-8 mb-4 text-2xl font-bold">Occasion Lease</h2>
      <p className="text-[#464646] mb-4 text-lg">
        Wil je profiteren van een hoogwaardige gebruikte elektrische auto? Onze
        occasion lease biedt je de mogelijkheid om milieubewust te rijden tegen
        een betaalbare prijs. Ontdek onze zorgvuldig geselecteerde occasions en
        geniet van de voordelen van elektrisch rijden zonder de kosten van een
        nieuw voertuig.
      </p>

      <h2 className="mt-8 mb-4 text-2xl font-bold">Waarom Kiezen Voor Ons?</h2>
      <p className="text-[#464646] mb-4 text-lg">
        Vergelijk Alle Modellen: Wij vergelijken alle elektrische auto modellen
        en aanbieders, zodat je altijd de beste keuze kunt maken voor jouw
        behoeften en budget. Altijd de Laagste Prijs: Wij garanderen altijd de
        laagste prijs voor jouw elektrische leaseauto. Je kunt vertrouwen op
        onze prijsgarantie voor de meest betaalbare lease-opties. Uitstekende
        Klantenservice: Ons toegewijde team staat altijd klaar om je te helpen.
        Of je nu vragen hebt over modellen, tarieven of service, wij zijn er om
        je te assisteren.
      </p>

      <h2 className="mt-8 mb-4 text-2xl font-bold">Neem Contact Met Ons Op</h2>
      <p className="text-[#464646] mb-4 text-lg">
        Heb je vragen of wil je meer informatie over onze elektrische
        leaseopties? Neem gerust contact met ons op via:
      </p>
      <p className="text-[#464646] mb-4 text-lg">
        Telefoon: <span className="font-bold">0634608126</span> E-mail:{" "}
        <span className="font-bold">info@elektrische-lease.nl</span> Bezoek Ons:
        Spijkernisse 3205 LA.
      </p>
      <p className="text-[#464646] mb-4 text-lg">
        Wij kijken ernaar uit om je te helpen de perfecte elektrische
        lease-oplossing te vinden die past bij jouw behoeften en budget.
      </p>
    </div>
  );
};

export default OnsAanbod;

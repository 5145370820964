import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import blogsData from "../data/blog.json";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import test from "../assets/elnllogoweb.ico";
import remarkInlineLinks from "remark-inline-links";

const BlogPage = ({ category, blogUrl }) => {
  const [contentData, setContentData] = useState([]);
  const blog = blogsData.find((blog) => blog.blog_url === blogUrl);

  useEffect(() => {
    if (blog && blog.content) {
      setContentData(blog.content);
    }
  }, [blogUrl]);

  if (!blog) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen text-xl font-bold text-primary-color">
        Blog niet gevonden
      </div>
    );
  }

  if (!contentData.length) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen text-5xl font-bold text-primary-color">
        Laden...
      </div>
    );
  }

  const getMarkdownClass = (type) => {
    switch (type) {
      case "h1":
        return "text-3xl sm:text-5xl font-black text-left mt-10 text-primary-color";
      case "h2":
      case "h2-1":
        return "text-3xl font-bold mt-10 text-[#464646]";
      case "h2-2":
      case "h2-3":
      case "h2-4":
      case "h2-5":
        return "text-3xl font-bold mt-8 text-[#464646]";
      case "h1-1":
        return "text-md text-[#464646] font-bold mt-6";
      case "h3":
      case "h3-1":
      case "h3-2":
      case "h3-3":
      case "h3-4":
      case "h3-5":
      case "h3-6":
      case "h3-7":
      case "h3-8":
        return "text-2xl font-bold mt-8 text-[#464646]";
      case "h4":
        return "text-xl font-bold mt-8 text-[#464646] leading-relaxed";
      case "p":
      case "p-1":
        return "mt-4 text-md text-[#464646] leading-relaxed";
      case "p-2":
      case "p-3":
        return "mt-4 text-[#464646] leading-relaxed";
      default:
        return "";
    }
  };

  const renderers = {
    link: ({ href, children }) => {
      return (
        <a href={href} rel="nofollow">
          {children}
        </a>
      );
    },
  };

  return (
    <div className="relative w-full min-h-screen mx-auto">
      <Helmet>
        <title>{contentData[0].text}</title>
        <meta name="description" content={contentData[1].text} />
        <link rel="icon" src={test} />
      </Helmet>
      <div className="relative w-full h-[40vh] overflow-hidden">
        {blog && blog.image && (
          <img
            src={blog.image}
            alt="Blog Banner"
            className="z-0 object-cover w-full h-full"
          />
        )}
      </div>

      {/* buttons */}
      <div className="w-[10vw] absolute ml-[8vw] mt-[20vh]">
        <Link to="/Elektrische-Lease-vergelijken?contract=Private+lease%3FsortOrder%3Dlaag-hoog&leaseType=Private+Lease">
          <button className="hidden sm:flex w-full justify-center text-white font-bold mb-2 bg-primary-color border-2  py-2 px-4 rounded-xl hover:bg-[#ec8f4c] transition-colors duration-300">
            Private lease
          </button>
        </Link>
        <Link to="/Elektrische-Lease-vergelijken?contract=Private+lease%3FsortOrder%3Dlaag-hoog&leaseType=Zakelijk">
          <button className="hidden sm:flex w-full justify-center text-white font-bold bg-primary-color border-2  py-2 px-4 rounded-xl hover:bg-[#ec8f4c] transition-colors duration-300">
            Zakelijke lease
          </button>
        </Link>
      </div>

      <article className="max-w-3xl px-4 mx-auto prose">
        {contentData.map((item, index) => (
          <ReactMarkdown
            key={index}
            remarkPlugins={[remarkInlineLinks]}
            className={getMarkdownClass(item.type)}
            components={{
              a: ({ node, ...props }) => (
                <Link
                  to={props.href}
                  className="text-primary-color hover:underline"
                  rel={props.href.startsWith("http") ? "nofollow" : null}
                >
                  {props.children}
                </Link>
              ),
            }}
          >
            {item.text}
          </ReactMarkdown>
        ))}
      </article>

      {/* <div className="mb-[10vh] text-[#464646] text-md mt-6 mx-auto max-w-4xl pl-4">
        Meer over 
        <Link to={`/${blog?.category}`}>
          <span className="font-bold text-primary-color">
            {blog?.category.split("-").join(" ")}
          </span>
        </Link>
        weten?
      </div> */}
      <div className="mb-[10vh]"></div>
      <Footer />
    </div>
  );
};

export default BlogPage;

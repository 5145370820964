import React from "react";
import { Link } from "react-router-dom";
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillInstagram,
} from "react-icons/ai";
import { RiTwitterXFill } from "react-icons/ri";

const Footer = () => {
  return (
    <footer className="bg-[#f2f2f2] border-2">
      <div className="w-full mx-auto">
        <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-5">
          <div>
            <h2 className="mb-6 font-bold text-md text-primary-color">
              Ons aanbod
            </h2>
            <ul className="text-[#464646] font-medium">
              <li className="mb-4">
                <a
                  href="/Elektrische-Lease-vergelijken"
                  className=" hover:underline"
                >
                  Alle merken
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="/Elektrische-Lease-vergelijken?contract=Private+lease"
                  className="hover:underline"
                >
                  Private Lease
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="/Elektrische-Lease-vergelijken?contract=Zakelijk"
                  className="hover:underline"
                >
                  Financial Lease
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="/Elektrische-Lease-vergelijken?contract=Occasion"
                  className="hover:underline"
                >
                  Occasion Lease
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 font-bold text-md text-primary-color">
              Zakelijk leasen
            </h2>
            <ul className="text-[#464646] font-medium">
              <li className="mb-4">
                <a
                  href="https://twitter.com/ElektrischeLeas"
                  className="hover:underline"
                >
                  Twitter
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://www.instagram.com/elektrischelease.nl/"
                  className="hover:underline"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 font-bold text-md text-primary-color">
              Over ons
            </h2>
            <ul className="text-[#464646] font-medium">
              <li className="mb-4">
                <a
                  href="https://elektrische-lease.nl/Bijtelling/bijtelling_elektrische_autos"
                  className="hover:underline"
                >
                  Bijtelling
                </a>
              </li>
              <li className="mb-4">
                <a href="/Aanbod" className="hover:underline">
                  Ons Aanbod
                </a>
              </li>
              <li className="mb-4">
                <a href="#" className="hover:underline">
                  Terms &amp; Conditions
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 font-bold text-md text-primary-color">Info</h2>
            <ul className="text-[#464646] font-medium">
              <li className="mb-4">
                <a
                  href="https://motorverzekeringen-vergelijk.nl"
                  className="hover:underline"
                >
                  motorverzekeringen
                </a>
              </li>
              <li className="mb-4">
                <a href="/sitemap.xml" className="hover:underline">
                  Sitemap
                </a>
              </li>
            </ul>
          </div>
          <div>
            <ul className="text-[#464646] font-medium">
              <li className="mb-4">
                <a>
                  <span className="font-bold">Locatie:</span>
                  <br></br> Spijkernisse 3205 LA
                </a>
              </li>

              <li className="mb-4">
                <a>
                  <span className="font-bold">Telefoonnummer:</span>
                  <br></br> 0634608126
                </a>
              </li>
              <li className="mb-4">
                <a>
                  <span className="font-bold">E-mail:</span>
                  <br></br> info@elektrische-lease.nl
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="px-4 py-6 bg-primary-color md:flex md:items-center md:justify-between">
          <span className="text-sm font-bold text-white sm:text-center">
            © 2023 <a href="">ElektrischeLease™</a>. All Rights Reserved.
          </span>
          <div className="w-[50%] flex mx-auto sm:mx-0 sm:justify-end">
            <p className="flex space-x-2 text-white">
              <AiFillFacebook size={40} />
              <AiFillLinkedin size={40} />
              <a href="https://twitter.com/ElektrischeLeas" target="_blank">
                <AiFillInstagram size={40} />
              </a>
              <a href="https://twitter.com/ElektrischeLeas" target="_blank">
                <RiTwitterXFill size={38} />
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
